
import EMBUDOS_COLORES from "../../../Constantes/EmbudoColor";
import EmbudoService from "../../../Services/EmbudoService";
import ConstantesStatusCadena from "@/Constantes/ConstantesStatusCadena";
export default {
  name: "embudo-gestor",
  components: {
    MenuSeleccionColores: () =>
      import("./componentes/MenuSeleccionColores.vue"),
  },
  data() {
    return {
      breadcrumbs: [
        { etiqueta: "Configuración" },
        { etiqueta: "Embudo de ventas" },
      ],
      statusEnProceso: ConstantesStatusCadena.oportunidadEnProceso,
      embudos: [],
      agregarPerfilObj: {
        nombre: "",
        descripcion: "",
      },
      coloresOpciones: [],
      editarTituloStr: "",
    };
  },
  mounted() {
    this.cargaInicial();
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      await this.obtenerEmbudos(false);
      this.loader(false);
    },
    async obtenerEmbudos(loader = true) {
      if (loader) this.loader(true);
      await EmbudoService.listarGestor({ ordenar: "orden_asc" })
        .then((resp) => {
          this.embudos = resp;
          this.embudos.map(
            (e) => ((e.menuColores = false), (e.mostrarInputEditar = false))
          );
          this.llenarOpcionesEmbudos();
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    llenarOpcionesEmbudos() {
      let coloresUsados = this.embudos.map((e) => e.color);
      let coloresOpciones = EMBUDOS_COLORES.ARRAY_EMBUDOS_COLORES.filter(
        (ec) => {
          if (!coloresUsados.includes(ec)) return ec;
        }
      );
      this.coloresOpciones = coloresOpciones;
    },
    async editarColor(embudo, color) {
      if (embudo.color == color) return;
      if (this.embudos.some((e) => e.color == color))
        return this.mostrarAlerta(
          "El color ya pertenece a otro embudo, selecciona uno diferente",
          "error"
        );
      this.loader(true);
      let datos = {
        embudoId: embudo.embudo_id,
        color: color,
      };
      await EmbudoService.editarColor(datos)
        .then(async (resp) => {
          await this.obtenerEmbudos(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    async editarTitulo(embudo) {
      if (this._.isEmpty(this.editarTituloStr))
        return this.mostrarAlerta("Ingresa un título válido", "error");

      this.loader(true);
      let datos = {
        embudoId: embudo.embudo_id,
        titulo: this.editarTituloStr,
      };
      await EmbudoService.editarTitulo(datos)
        .then(async (resp) => {
          await this.obtenerEmbudos(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    async eliminarEmbudo(embudo) {
      this.loader(true);
      let datos = {
        embudoId: embudo.embudo_id,
      };
      await EmbudoService.eliminar(datos)
        .then(async (resp) => {
          await this.obtenerEmbudos(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    async agregarEmbudo(index, embudo) {
      if (this._.isEmpty(embudo.titulo))
        return this.mostrarAlerta("Ingresa un título válido", "error");

      if (this._.isEmpty(embudo.color))
        return this.mostrarAlerta("Selecciona un color", "error");

      if (this.embudos.some((e) => e.embudo_id && e.color == embudo.color)) {
        let mensaje =
          "El color ya pertenece a otro embudo, selecciona uno diferente";
        return this.mostrarAlerta(mensaje, "error");
      }

      this.loader(true);
      let embudoPredecesorId = this.embudos[index - 1].embudo_id;
      let datos = {
        color: embudo.color,
        titulo: embudo.titulo,
        embudoPredecesorId: embudoPredecesorId,
      };
      await EmbudoService.agregar(datos)
        .then(async (resp) => {
          await this.obtenerEmbudos(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    mostrarInputEditar(index) {
      this.embudos.map((e) => (e.mostrarInputEditar = false));
      this.embudos[index].mostrarInputEditar = true;
      this.editarTituloStr = this.embudos[index].titulo;
      this.embudos = this._.cloneDeep(this.embudos);
    },
    ocultarInputEditar() {
      this.embudos.map((e) => (e.mostrarInputEditar = false));
      this.editarTituloStr = "";
    },
    mostrarCardAgregarEmbudo(index) {
      let agregarEmbudoObj = {
        color: "",
        titulo: "",
        esCardAgregar: true,
        menuColores: false,
      };
      this.embudos.splice(index + 1, 0, agregarEmbudoObj);
    },
    cancelarAlta(index) {
      this.embudos.splice(index, 1);
    },
  },
};
